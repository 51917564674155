exports.components = {
  "component---src-pages-cenik-js": () => import("./../../../src/pages/cenik.js" /* webpackChunkName: "component---src-pages-cenik-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-mne-js": () => import("./../../../src/pages/o-mne.js" /* webpackChunkName: "component---src-pages-o-mne-js" */),
  "component---src-pages-ochrana-osobnich-udaju-js": () => import("./../../../src/pages/ochrana-osobnich-udaju.js" /* webpackChunkName: "component---src-pages-ochrana-osobnich-udaju-js" */),
  "component---src-pages-reference-js": () => import("./../../../src/pages/reference.js" /* webpackChunkName: "component---src-pages-reference-js" */),
  "component---src-pages-sluzby-js": () => import("./../../../src/pages/sluzby.js" /* webpackChunkName: "component---src-pages-sluzby-js" */)
}

